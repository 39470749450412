import { styled } from "styles/stitches.config";
import { rem } from "polished";

const Text = styled("p", {
  fontFamily: "$raleway",
  fontSize: rem(13),
  lineHeight: "180%",

  variants: {
    align: {
      center: {
        textAlign: "center",
      },
    },
    type: {
      semiBold: {
        fontWeight: 600,
      },
      bold: {
        fontWeight: "bold",
      },
      lead: {
        fontSize: rem(11),
        fontWeight: 600,
        "@xxl": {
          fontSize: rem(18),
        },
      },
    },
    size: {
      sm: {
        fontSize: rem(12),
      },
    },
  },
});

export default Text;
