import { styled } from 'styles/stitches.config'

export const Cta = styled('section', {
  backgroundColor: '$emerald500',
  textAlign: 'center',
})

export const Content = styled('div', {
  maxWidth: 750,
  margin: '0 auto',

  '& h2': {
    marginBottom: 15,
    color: '$white'
  },

  '& p': {
    color: '$white',
    marginBottom: 30,
  },

  '& button': {
    margin: '0 auto',

    '& svg': {
      marginLeft: 5,
      marginTop: '-2px'
    }
  }
})