export const countComments = (comments) => {
  const flatComments = comments.flatMap(comment => {
    if (comment.replies) {
      return [
        {...comment, replies: false},
        ...comment.replies.map(reply => ({...reply, status: false}))
      ]
    }
    return {...comment, replies: false};
  });

  const commentsActive = flatComments.filter(item => item.status)

  return commentsActive?.length || 0
}

export const transformHttpToHttps = (url) => {
  if (!url) return ''
  return url.replace('http://', 'https://')
}
