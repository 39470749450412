import { styled } from 'styles/stitches.config'
import { rem } from 'polished'

export const Headline = styled('div', {
  marginBottom: 25,

  textAlign: 'center',

  '@xxl': {
    marginBottom: 50,
  },

  variants: {
    color: {
      inverse: {
        '& h2': {
          color: '$white'
        }
      },
    }
  },



  '& span': {
    color: '$emerald500'
  }
})

export const Category = styled('p', {
  position: 'relative',

  marginBottom: 16,

  fontSize: rem(12),
  color: '$emerald500',
  fontFamily: '$raleway',
  fontWeight: 600,
  textTransform: 'uppercase',
  letterSpacing: 2,

  '@xxl': {
    fontSize: rem(13),
  },

  '&::before': {
    content: '',

    position: 'absolute',
    bottom: -4,

    width: 30,
    height: 2,
    left: '50%',
    transform: 'translate(-50%,0)',

    backgroundColor: '$emerald500',
  }

})