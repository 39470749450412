import Image from 'next/image'
import SliderSlick from 'react-slick'

import Navbar from 'components/Navbar'
import Container from 'components/Container'
import Button from 'components/Button'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'

import BannerExample from 'public/banner_gramas_small-compressed.webp'
import MaskIMage from 'public/images/mask-banner-home.svg'

import { BannerType } from './types'

import * as S from './styles'

const BackArrow = ({ currentSlide, slideCount, ...props }: any) => (
  <S.Content {...props}>
    <ArrowBackIcon w={10} h={10} color="white" />
  </S.Content>
)

const NextArrow = ({ currentSlide, slideCount, ...props }: any) => (
  <S.Content {...props}>
    <ArrowForwardIcon w={10} h={10} color="white" />
  </S.Content>
)

const Banner = ({ banner }: BannerType) => {
  const settings = {
    dots: false,
    arrows: true,
    speed: 500,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <BackArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  }

  return (
    <S.Banner>
      <S.Content>
        <Container>
          <S.TextTop size="sm" align="center">
            Bem vindo a <span>Central da Grama.</span> Nós fornecemos as
            melhores gramas para você.
          </S.TextTop>
          <Navbar />

          <S.Slider>
            <SliderSlick {...settings}>
              {banner?.map((item) => (
                <S.Box key={item.id}>
                  <div>
                    <h2>{item.title.rendered}</h2>
                    <S.BoxSubTitle type="lead">
                      {item.excerpt.rendered?.replace(/<[^>]*>?/gm, '')}
                    </S.BoxSubTitle>
                  </div>
                  <a href={item?.acf?.button_action}>
                    <Button
                      shape="rounded"
                      title={item?.acf?.button_title}
                    >
                      {item?.acf?.button_text}
                    </Button>
                  </a>
                </S.Box>
              ))}
            </SliderSlick>
          </S.Slider>
        </Container>
      </S.Content>

      <S.Image_Wrap>
        <Image
          src={BannerExample}
          layout="fill"
          alt="banner contendo grama"
          // placeholder="blur"
          priority
        />
      </S.Image_Wrap>

      <S.Mask_Wrap>
        <S.Mask
          src={MaskIMage}
          alt="mascara do banner"
          layout="fill"
          objectFit="cover"
        />
      </S.Mask_Wrap>
    </S.Banner>
  )
}

export default Banner
