import { ArrowForwardIcon } from '@chakra-ui/icons'

import Container from 'components/Container'
import Text from 'components/Typography/Text'
import Button from 'components/Button'

import * as S from './styles'
import { useRouter } from 'next/router'

const Cta = () => {
  const router = useRouter()
  return (
    <S.Cta>
      <Container>
        <S.Content>
          <h2>Tenha o melhor preço de grama para o seu jardim</h2>
          <Text type="semiBold">
            Encontre a melhor grama para o seu jardim com preços imbatíveis.
            Faça agora mesmo o seu orçamento e garanta a beleza do seu espaço
            verde. Não perca mais tempo, aproveite já!
          </Text>
          <Button
            color="inverse"
            shape="rounded"
            onClick={() => router.push('/comprar-grama')}
            title="comprar grama para jardim"
          >
            faça seu orçamento
            <ArrowForwardIcon w={4} h={4} />
          </Button>
        </S.Content>
      </Container>
    </S.Cta>
  )
}

export default Cta
