import Button from 'components/Button'

import { CardProps } from './types'

import * as S from './styles'

const shimmer = (w: any, h: any) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`

const toBase64 = (str: any) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str)

const Card = ({ ...props }: CardProps) => {
  return (
    <S.Card {...props} onClick={props.onPress}>
      <S.ImageWrap>
        <S.Image
          src={props.image}
          title={props.metaImgTitle}
          alt={props.metaImgTitle}
          // placeholder="blur"
          layout="fill"
          objectFit="cover"
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            shimmer('100%', '100%')
          )}`}
        />
      </S.ImageWrap>
      <S.Texts>
        <S.Title title={props.metaTitle}>{props.title}</S.Title>
        <S.Description>
          {props?.description?.replace(/<[^>]*>?/gm, '')}
        </S.Description>
        <Button color="transparent" onClick={props.onPress}>
          Ver detalhes
        </Button>
      </S.Texts>
    </S.Card>
  )
}

export default Card
