import { styled } from 'styles/stitches.config'
import { rem } from 'polished'
import ImageNext from 'next/image'

import Leaf1 from 'public/images/icons/leaf-bg-1.svg'
import Leaf2 from 'public/images/icons/leaf-bg-2.svg'

export const Texts = styled('div', {
  position: 'relative',

  paddingInline: 25,

  '&::before': {
    content: `url(${Leaf1.src})`,
    visibility: 'hidden',
    opacity: 0,

    position: 'absolute',
    left: 20,
    top: 0,

    transition: 'all 0.3s',
  },

  '&::after': {
    content: `url(${Leaf2.src})`,
    visibility: 'hidden',
    opacity: 0,

    position: 'absolute',
    right: 30,
    bottom: 10,

    transition: 'all 0.3s',
  },
})
export const Card = styled('div', {
  position: 'relative',

  textAlign: 'center',
  borderRadius: 8,
  overflow: 'hidden',
  border: 'solid 1px',
  borderColor: 'transparent',
  transition: 'all 0.3s',

  '&:hover': {
    border: 'solid 1px',
    borderColor: '$emerald500',
    cursor: 'pointer',
    transition: 'all 0.3s',

    [`& ${Texts}`]: {
      '&::before, &::after': {
        opacity: 1,
        visibility: 'visible',
        transition: 'all 0.3s',
      },
    },
  },
})

export const ImageWrap = styled('figure', {
  position: 'relative',

  width: '100%',
  height: 230,
  marginBottom: 14,
})

export const Image = styled(ImageNext, {})

export const Title = styled('h3', {
  marginBottom: 14,

  fontSize: '20px !important',
  color: '$grey900',
  lineHeight: '120%',
  fontFamily: '$domine',
  fontWeight: 'bold',
  textTransform: 'capitalize',
})

export const Description = styled('p', {
  marginBottom: 14,

  color: '$grey500',
  fontSize: rem(15),
  fontFamily: '$raleway',
  lineHeight: '180%',
  overflow: 'hidden',
  'text-overflow': 'ellipsis',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '2',
})
