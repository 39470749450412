import { styled, keyframes } from 'styles/stitches.config'
import Image from 'next/image'
import { size } from 'polished'

import Text from 'components/Typography/Text'

export const Banner = styled('div', {
  position: 'relative',

  ...size('auto', '100%'),
  paddingTop: 40,
  paddingBottom: 40,
  height: '75vh',

  '& .slick-prev:before, & .slick-next:before': {
    display: 'none',
  },

  '& .slick-prev': {
    left: 30,
    top: '30%',
  },
  '& .slick-next': {
    right: 50,
    top: '30%',
  },

  '&::before': {
    content: '',

    position: 'absolute',
    top: 0,
    left: 0,

    ...size('100%'),

    backgroundColor: '$emerald200',
    opacity: 0.38,
    zIndex: 2,
  },

  '@sm': {
    height: '45vh',
  },

  '@md': {
    height: '60vh',
  },

  '@lg': {
    height: '75vh',
  },

  '@xl': {
    height: '90vh',
  },

  '@xxl': {
    height: '80vh',
  },

  '& h2': {
    color: '$emerald500',
    marginBottom: 15,
  },

  // '&::after': {
  //   content: '',

  //   position: 'absolute',
  //   ...size('100%'),

  //   ...linearGradient({
  //     colorStops: ['#ECFDF5 100%', '#ECFDF5 0%'],
  //     toDirection: 'to top right',
  //     fallback: '#FFF',
  //   }),
  //   zIndex: 2
  // },
})

export const Image_Wrap = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,

  width: '100%',
  height: '100%',

  zIndex: 1,
})

export const TextTop = styled(Text, {
  color: '$grey500',
  mb: 10,

  '& span': {
    color: '$emerald500',
  },
})

export const Content = styled('div', {
  position: 'relative',

  zIndex: 5,
})

export const Mask_Wrap = styled('div', {
  position: 'absolute',
  bottom: -35,
  left: 0,

  width: '100%',
  height: 80,

  zIndex: 4,
  display: 'none',

  '@md': {
    display: 'block',
  },
})

export const Mask = styled(Image, {})

const scaleUp = keyframes({
  '0%': { transform: 'scale(1)' },
  '50%': { transform: 'scale(1.2)' },
  '100%': { transform: 'scale(1)' },
});

export const Box = styled('div', {
  width: 'max-content !important',
  padding: '25px',
  margin: '30px auto 0',

  textAlign: 'center',
  borderRadius: '0 0 50px 50px',
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',

  '@md': {
    margin: '75px auto 0',
  },

  '@xxl': {
    padding: 56,
    margin: '128px auto 0',
    maxWidth: 800,
  },

  'h2': {
    textTransform: 'capitalize',
    
  },
  
  '@maxmd': {
    margin: '0 auto',

    'h2': {
      fontSize: '1.1rem !important',
    }
  },

  'a > button': {
    animation: `${scaleUp} 1s ease-in-out infinite`,
  }
})

export const BoxSubTitle = styled(Text, {
  color: '$grey600',
  marginBottom: 15,
})

export const Slider = styled('div', {
  marginTop: 50,
  textAlign: 'center',
})
