import { styled } from 'styles/stitches.config'
import ButtonComponent from "components/Button";

export const Grass = styled('section', {

})

export const Button = styled(ButtonComponent, {
    position: 'relative',
  
    marginTop: 70,
    display: 'inline-flex',
    alignItems: 'center',
    left: '50%',
  
    transform: 'translateX(-50%)',
  
    '& svg': {
      marginLeft: 5,
      marginTop: '-2px'
    }
  })