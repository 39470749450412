import { SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import { GrassType } from './types';
import Link from 'next/link';

import Headline from 'components/Headline';
import Container from 'components/Container';
import Card from 'components/Card';

import GrassImage from 'public/images/grass1-example.jpg';
import Grass2Image from 'public/images/grass2-example.jpg';

import * as S from './styles';
import { transformHttpToHttps } from 'utils';
import { useRouter } from 'next/router';
import { ArrowForwardIcon } from '@chakra-ui/icons';

const getSplittedName = (value: any) => {
  const [name] = value.toLowerCase().split('GRAMA ');
  return name;
}

const Grass = ({ grasses }: any) => {
  const router = useRouter();
  const isMobile = useBreakpointValue({ base: true, md: false }); 

  const grassesList = isMobile ? grasses?.slice(0, 3) : grasses

  return (
    <S.Grass>
      <Container>
        <Headline category="Gramas" title="Tipos de" highlight="Grama" />
        <SimpleGrid columns={[1, 1, 2, null, 3]} spacing={15}>
          {grassesList?.map((item: any) => (
            <Card
              key={`grass-${item.id}`}
              image={transformHttpToHttps(item.x_featured_media_original)}
              title={getSplittedName(item.title.rendered)}
              description={`${item.excerpt.rendered} ...`}
              onPress={() => router.push(item.slug)}
              metaImgTitle={item?.acf?.title_img_othergrass}
              metaImgAlt={item?.acf?.alt_img_othergrass}
              metaTitle={item?.acf?.title_text_othergrass}
            />
          ))}
        </SimpleGrid>
        <Link href="/dicas">
          <S.Button
            shape="rounded"
            as="a"
            href="/dicas"
            title="ver todas as dicas de grama"
          >
            Ver todas <ArrowForwardIcon w={4} h={4} />
          </S.Button>
        </Link>
      </Container>
    </S.Grass>
  );
}

export default Grass;
