import { HeadlineProps } from "./types";
import * as S from "./styles";

const Text = ({ ...props }: HeadlineProps) => {
  return (
    <S.Headline {...props}>
      <S.Category>{props.category}</S.Category>
      <h2>
        {props.title} <span>{props.highlight}</span>
      </h2>
    </S.Headline>
  );
};

export default Text;
