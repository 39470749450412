import type { NextPage } from 'next'
import Head from 'next/head'
import { getGrass } from 'services/grass/action'

import {
  getBanner,
  getCustomers,
  getUtilizations,
} from 'services/banner/action'

import Banner from './components/Banner'
import Grass from './components/Grass'
import CTA from './components/CTA'
import Footer from 'components/Footer'

import * as S from './styles.home'
import { getList } from 'services/tips/action'
import dynamic from 'next/dynamic'

const Utilzation = dynamic(() => import('./components/Utilzation'), {
  ssr: false,
})
const Tips = dynamic(() => import('./components/Tips'), { ssr: false })
const Customers = dynamic(() => import('./components/Customers'), {
  ssr: false,
})

const Home: NextPage<any> = ({ grasses,  banner, utilizations, tips, customers }) => {
  return (
    <>
      <Head>
        <title>Comprar grama é na Central, gramas pelo menor preço m2</title>
        <meta
          name="title"
          content="Comprar grama é na Central, gramas pelo menor preço m2"
        />
        <meta
          name="description"
          content="Gramas para jardim, Campo de Futebol, Taludes, Paisagismo, Obras em geral. Preço ainda mais baixo ao Comprar a Grama c/ Plantio, Acesse a Central."
        />
        
        <meta name="keywords" content="grama, gramas, gramas por m2, comprar grama, comprar gramas, comprar grama por m2, grama preço, grama preço m2, grama natural"/>

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://centraldagrama.com/" />
        <meta
          property="og:title"
          content="Comprar grama é na Central, gramas pelo menor preço m2"
        />
        <meta
          property="og:description"
          content="Gramas para jardim, Campo de Futebol, Taludes, Paisagismo, Obras em geral. Preço ainda mais baixo ao Comprar a Grama c/ Plantio, Acesse a Central."
        />
        <meta
          property="og:image"
          content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://centraldagrama.com/" />
        <meta
          property="twitter:title"
          content="Comprar grama é na Central, gramas pelo menor preço m2"
        />
        <meta
          property="twitter:description"
          content="Gramas para jardim, Campo de Futebol, Taludes, Paisagismo, Obras em geral. Preço ainda mais baixo ao Comprar a Grama c/ Plantio, Acesse a Central."
        />
        <meta
          property="twitter:image"
          content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"
        />
      </Head>
      <S.Main>
        <Banner banner={banner} />
        <Grass grasses={grasses} />
        <Utilzation utilizations={utilizations} />
        <CTA />
        <Tips tips={tips} />
        <Customers customers={customers} />
        <Footer />
      </S.Main>
    </>
  )
}

export default Home

export async function getStaticProps() {
  const grasses = await getGrass()
  const banner = await getBanner()
  const utilizations = await  getUtilizations()
  const tips = await getList()
  const customers = await getCustomers()

  return {
    props: {
      grasses,
      banner,
      utilizations,
      tips,
      customers
    },
    revalidate: 10
  }
}
